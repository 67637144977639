import React from 'react'

import { Box, Heading, Text } from '@chakra-ui/core'

export default () => {
  return (
    <Box>
      <Heading pt={10}>รู้หรือไม่..ทุนอังกฤษไม่ได้มีแค่ Chevening</Heading>
      <Text py={4}>
        เชื่อว่าหลายๆ คนที่กำลังมองหาทุนศึกษาต่อต่างประเทศ โดยเฉพาในประเทศอังกฤษ
        มักจะรู้จักทุน Chevening
        ซึ่งเป็นทุนสำหรับการศึกษาต่อในประเทศอังกฤษจากรัฐบาลสหราชอาณาจักรแบบเต็มจำนวน
        ซึ่งทุน Chevening นั้นได้รับความนิยมจากบุคคลจากทั่วทุกมุมโลก
        และด้วยจำนวนทุนที่มีจำกัด จึงทำให้เกิดการแข่งขันที่สูง
        จนทำให้หลายคนต้องพลาดโอกาส และอาจรู้สึกท้อแท้ในการเรียนต่อ
      </Text>
      <Text py={4}>
        ในวันนี้ บอกทุนจึงขอนำเสนอทุนสำหรับการศึกษาต่อในสหราชอาณาจักรอันหลากหลาย
        ไม่ว่าจะเป็นทุนเต็มจำนวน ทุนวิจัย หรือทุนบางส่วน
        ที่เปิดโอกาสให้นักเรียนต่างชาติ ได้เข้ามาสมัคร
        และรับโอกาสในการสานต่อความฝันและอนาคตของตน
      </Text>
      <ol>
        <li>
          Gates Cambridge Scholarships ผู้ให้ทุน: Bill & Melinda Gates
          Foundation ระดับการศึกษา: ปริญญาโท และ ปริญญาเอก มหาวิทยาลัย:
          University of Cambridge ประเภทของทุน: ทุนเต็มจำนวน จำนวนทุน: 80 ทุน
          ระยะเวลาการเปิดรับสมัคร: 3 ธันวาคม 2019 - 7 มกราคม 2020
          คุณสมบัติของผู้สมัคร: เป็นนักศึกษาต่างชาตินอกเขตราชอาณาจักร
          เลือกเรียนหลักสูตรที่กำหนดไว้แบบเต็มเวลา ณ มหาวิทยาลัยแคมบริดจ์
          มีความเป็นเลิศทางด้านวิชาการ มีศักยภาพในการเป็นผู้นำ วิธีการสมัคร:
          สามารถสมัครได้ผ่านทางเว็บไซต์
          https://www.gatescambridge.org/apply/how-apply
        </li>
        <li>
          Clarendon Scholarships at the University of Oxford ผู้ให้ทุน:
          กองทุนการศึกษา Clarendon ระดับการศึกษา: ปริญญาโท และ ปริญญาเอก
          มหาวิทยาลัย: University of Oxford ประเภทของทุน: ทุนเต็มจำนวน
          ครอบคลุมค่าครองชีพสำหรับ 1 ปี จำนวนทุน: 50 ทุน
          ระยะเวลาการเปิดรับสมัคร: 1 ธันวาคม 2019 - 31 มกราคม 2020
          คุณสมบัติของผู้สมัคร: เป็นนักศึกษาต่างชาตินอกเขตราชอาณาจักร
          เลือกเรียนหลักสูตรที่กำหนดไว้แบบเต็มเวลา ณ มหาวิทยาลัยแคมบริดจ์
          มีความเป็นเลิศทางด้านวิชาการ มีศักยภาพในการเป็นผู้นำ วิธีการสมัคร:
          สามารถสมัครได้ผ่านทางเว็บไซต์
          https://www.ox.ac.uk/admissions/graduate/courses/courses-a-z-listing?wssl=1
        </li>
        <li>
          Westminster Full International Scholarships ผู้ให้ทุน: University of
          Westminster ระดับการศึกษา: ปริญญาโท และ ปริญญาเอก มหาวิทยาลัย:
          University of Westminster ประเภทของทุน: ทุนเต็มจำนวน
          ครอบคลุมค่าเล่าเรียน จำนวนทุน: 30 ทุน ระยะเวลาการเปิดรับสมัคร: 1
          มกราคม 2020 - 31 มีนาคม 2020 คุณสมบัติของผู้สมัคร:
          เป็นนักศึกษาต่างชาตินอกเขตราชอาณาจักร
          เลือกเรียนหลักสูตรที่กำหนดไว้แบบเต็มเวลา ณ มหาวิทยาลัยแคมบริดจ์
          มีความเป็นเลิศทางด้านวิชาการ มีศักยภาพในการเป็นผู้นำ วิธีการสมัคร:
          สามารถสมัครได้ผ่านทางเว็บไซต์
          https://www.westminster.ac.uk/study/fees-and-funding/scholarships/westminster-full-international-scholarship-january-start
          (ยังไม่เปิดรับสมัคร)
        </li>
        <li>
          Nottingham Trent University ผู้ให้ทุน: Nottingham Trent University
          ระดับการศึกษา: ปริญญาโท และ ปริญญาเอก มหาวิทยาลัย: University of
          Oxford ประเภทของทุน: ทุนเต็มจำนวน ครอบคลุมค่าครองชีพสำหรับ 1 ปี
          จำนวนทุน: 25 ทุน ระยะเวลาการเปิดรับสมัคร: 1 ธันวาคม 2019 - 31 มกราคม
          2020 คุณสมบัติของผู้สมัคร: เป็นนักศึกษาต่างชาตินอกเขตราชอาณาจักร
          เลือกเรียนหลักสูตรที่กำหนดไว้แบบเต็มเวลา ณ มหาวิทยาลัยแคมบริดจ์
          มีความเป็นเลิศทางด้านวิชาการ มีศักยภาพในการเป็นผู้นำ วิธีการสมัคร:
          สามารถดาวน์โหลดใบสมัครได้ผ่านทาง
          https://www.ntu.ac.uk/international/scholarships-and-fees/scholarships
        </li>
        <li>
          Commonwealth Masters Scholarships ผู้ให้ทุน: กองทุนการศึกษา
          Commonwealth Masters ระดับการศึกษา: ปริญญาโท มหาวิทยาลัย:
          มหาวิทยาลัยในสหราชอาณาจักร ประเภทของทุน: ทุนเต็มจำนวน จำนวนทุน: 50 ทุน
          ระยะเวลาการเปิดรับสมัคร: 1 ธันวาคม 2019 - 31 มกราคม 2020
          คุณสมบัติของผู้สมัคร: เป็นนักศึกษาต่างชาตินอกเขตราชอาณาจักร
          เลือกเรียนหลักสูตรที่กำหนดไว้แบบเต็มเวลา ณ มหาวิทยาลัยแคมบริดจ์
          มีความเป็นเลิศทางด้านวิชาการ มีศักยภาพในการเป็นผู้นำ วิธีการสมัคร:
          สามารถสมัครได้ผ่านทางเว็บไซต์
          https://www.ox.ac.uk/admissions/graduate/courses/courses-a-z-listing?wssl=1
        </li>
      </ol>
    </Box>
  )
}
